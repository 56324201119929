<template>
  <div class="included">
    <div class="nav">
      <div
        class="includednav_a"
        v-for="(v, i) in content"
        :key="i"
        @click="xuan(i)"
        :class="i == flag ? 'active' : ''"
      >
        {{ v.text }}
      </div>
    </div>
    <!-- 搜索框 -->
    <div class="in_search">
      <input v-model="search" placeholder="请输入文件名搜索" />
      <div @click="searchFile" class="search">
        <img src="../../../../assets/images/included_search.png" />
      </div>
    </div>
    <!-- 提示 -->
    <div class="tip">
      -版权认领需实名认证，认领后的所有版权收益将归入确权人-
    </div>
    <!-- 列表 -->
    <div class="table">
      <el-table
        v-if="tableData.length > 0"
        :data="tableData"
        style="width: 100%"
        :default-sort="{ prop: 'date' }"
      >
        <el-table-column align="center" label="序号" width="100">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column
          prop="fileName"
          show-overflow-tooltip
          label="文件名"
          width="500"
        >
        </el-table-column>
        <el-table-column label="版权状态">
          <div
            v-for="(v, i) in state"
            :key="i"
            :class="i == flag ? '' : 'display'"
            class="state"
          >
            <img
              v-if="i == flag && i == 0"
              src="../../../../assets/images/banquan.png"
            />
            <img
              v-if="i == flag && i == 1"
              src="../../../../assets/images/lanbanquan.png"
            />
            <img
              v-if="i == flag && i == 2"
              src="../../../../assets/images/lanbanquan.png"
            />
            {{ v.text }}
          </div>
        </el-table-column>
        <el-table-column
          prop="wordPackage"
          label="收益(现金/字符包)"
          sortable
          width="200"
        >
          <template slot-scope="scope">
            <div class="state">
              <span v-text="scope.row.cash"></span>/<span
                v-text="scope.row.wordPackage"
              ></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="publishTime"
          label="上传时间"
          sortable
          width="200"
        >
        </el-table-column>
        <el-table-column v-if="aflag == 0" prop="operation" label="操作">
          <template slot-scope="scope">
            <div
              v-for="(v, i) in action"
              :key="i"
              :class="i == flag ? '' : 'display'"
              class="state"
            >
              <img
                v-if="i == flag && i == 0"
                src="../../../../assets/images/renling.png"
              />
              <img
                v-if="i == flag && i == 1"
                src="../../../../assets/images/jiuchuo.png"
              />
              <span @click="go(scope.$index, v.text)" v-text="v.text"></span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 无数据显示 -->
    <div v-if="tableData.length == 0" class="nofile">
      <img src="../../../../assets/images/no-file.png" />
      <div>暂无文件</div>
    </div>
    <!-- 分页 -->
    <el-pagination
      v-if="tableData.length > 0 && pageCount > 1"
      class="pagination"
      background
      layout="prev, pager, next"
      prev-text="上一页"
      next-text="下一页"
      :pager-count="5"
      :page-size="pageSize"
      :page-count="pageCount"
      :current-page="pageNum"
      @current-change="currentChange"
    >
    </el-pagination>
    <!-- 认证弹窗 -->
    <div v-if="ren_flag" class="claim">
      <img
        @click="close"
        class="close"
        src="../../../../assets/images/close.png"
      />
      <div class="renzheng">
        <img src="../../../../assets/images/renzheng.png" />
        <span>认领版权需要实名认证</span>
      </div>
      <div class="xuanze">
        <div @click="gorealName" class="yes">现在认证</div>
        <div class="xian"></div>
        <div @click="close" class="no">取消</div>
      </div>
    </div>
    <!-- 认领弹窗 -->
    <div v-if="claimn_flag" class="claimn">
      <div class="claimnNav">
        <div class="title">
          <img src="../../../../assets/images/renling.png" />
          <span>待认领版权-认领方式</span>
        </div>
        <img
          @click="closecl"
          class="title_x"
          src="../../../../assets/images/close.png"
        />
      </div>
      <div class="wangzhi">
        <input
          @click="dian"
          style="cursor: pointer"
          id="websit"
          type="radio"
          name="proof"
        />
        <label for="websit">网址佐证</label>
      </div>
      <div class="wangzhi">
        <input
          @click="dian"
          style="cursor: pointer"
          id="photo"
          type="radio"
          name="proof"
        />
        <label for="photo">照片或资料佐证</label>
      </div>
      <span class="w-title">网址佐证</span>
      <input v-model="website" class="shuruw" placeholder="请输入网址" />
      <span class="w-title">照片或资料佐证</span>
      <input
        id="photos"
        type="file"
        @change="doRealUpload"
        class="shuruw"
        name="frontFile"
        ref="backFile"
        style="opacity: 0"
      />
      <div v-if="flie == ''" class="file-tip" @click="uploadFile">
        点击上传照片或资料佐证
      </div>
      <div
        v-if="flie != ''"
        class="file-tip"
        @click="uploadFile"
        v-text="flie"
      ></div>
      <span class="w-title">备注</span>
      <textarea v-model="r_remark" placeholder="请输入备注" />
      <div class="botton">
        <el-button @click="submit" type="primary">提交</el-button>
        <el-button @click="closecl">取消</el-button>
      </div>
    </div>
    <!-- 纠错弹窗 -->
    <div v-if="error_flag" class="claimn">
      <div class="claimnNav">
        <div class="title">
          <img src="../../../../assets/images/lanbanquan.png" />
          <span>版权纠错</span>
        </div>
        <img
          @click="closeer"
          class="title_x"
          src="../../../../assets/images/close.png"
        />
      </div>
      <span class="w-title">著作权人有误</span>
      <input v-model="owner" class="shuruw" placeholder="请输入准确著作权人" />
      <span class="w-title">网址佐证</span>
      <input v-model="websiter" class="shuruw" placeholder="请输入网址" />
      <span class="w-title">照片或资料佐证</span>
      <input
        @change="doRealUpload"
        id="file"
        type="file"
        class="shuruw"
        name="frontFile"
        ref="backFile"
        style="opacity: 0"
      />
      <div v-if="flie == ''" class="file-tip" @click="uploadFile">点击上传</div>
      <div
        v-if="flie != ''"
        class="file-tip"
        @click="uploadFile"
        v-text="flie"
      ></div>
      <span class="w-title">备注</span>
      <textarea v-model="remark" placeholder="请输入备注" />
      <div class="botton">
        <el-button @click="submiter" type="primary">提交</el-button>
        <el-button @click="closeer">取消</el-button>
      </div>
    </div>
    <!-- 提交成功 -->
    <div v-if="success_flag" class="success">
      <img src="../../../../assets/images/chenggong.png" />
      <span
        >提交成功，我们将在1-3个工作日内处理完成，出现无法核实等复杂情况时，我们将及时和您沟通。感谢您的支持！</span
      >
    </div>
  </div>
</template>

<script>
import { apply, error, confirmPermissionList } from "api/included";
import { upload } from "api/common";
export default {
  data() {
    return {
      //版权状态
      state: [
        { id: 1, text: "待认领" },
        { id: 2, text: "已认领" },
        { id: 3, text: "已认领" },
      ],
      //操作
      action: [
        { id: 1, text: "认领" },
        { id: 2, text: "纠错" },
      ],
      aflag: 0,
      //导航栏选择
      content: [
        { id: 1, text: "版权认领" },
        { id: 2, text: "版权纠错" },
        { id: 3, text: "我的认领" },
      ],
      //导航flag
      flag: 0,
      //收录确权列表
      tableData: [],
      //认证弹窗
      ren_flag: false,
      //认领弹窗
      claimn_flag: false,
      //提交成功提示
      success_flag: false,
      //网址信息
      website: "",
      //纠错弹窗
      error_flag: false,
      //著作权人
      owner: "",
      //纠错网址
      websiter: "",
      //当前页
      pageNum: 1,
      //一页大小
      pageSize: 11,
      //总页数
      pageCount: 1,
      //文章序列
      id: 0,
      //纠错备注
      remark: "",
      //认领备注
      r_remark: "",
      //列表类型
      type: 1,
      //文件名搜索
      search: "",
      //网址佐证标识
      websitFlag: false,
      //资料佐证标识
      photoFlag: false,
      //资料文件名
      flie: "",
      // 文件路径
      fileUrl: "",
      //纠错资料
      file: "",
    };
  },
  methods: {
    //搜索文件名
    searchFile() {
      this.getList();
    },
    //选择页数
    currentChange(cur) {
      this.pageNum = cur;
      this.getList();
    },
    //选择操作方式
    go(i, v) {
      if (v == "认领") {
        this.goren(i);
      }
      if (v == "纠错") {
        this.error_flag = true;
      }
    },
    //获取收录列表
    async getList() {
      //参数
      let data = {
        type: this.type,
        fileName: this.search,
        page: this.pageNum,
        limit: 10,
      };
      //发送请求
      let res = await confirmPermissionList(data);
      //判断响应是否成功
      if (res.code == 200) {
        let data = res.data.data;
        //设置总页数
        this.pageCount = Math.ceil(res.data.total / 10);
        //设置收录列表
        this.tableData = data;
      } else {
        //清空收录列表
        this.tableData = [];
      }
    },
    //导航选择
    xuan(i) {
      //设置选中状态
      this.flag = i;
      //设置参数
      this.type = i + 1;
      //是否显示操作列表
      if (this.type == 3) {
        this.aflag = 1;
      }
      if (this.type == 1) {
        this.aflag = 0;
      }
      if (this.type == 2) {
        this.aflag = 0;
      }
      //清空搜索关键词
      this.search = "";
      //获取数据
      this.getList();
    },
    //认证弹窗
    goren(i) {
      //判断是否实名
      if (this.$store.state.realNameData == null) {
        this.ren_flag = true;
      } else {
        this.claimn_flag = true;
        this.id = i;
      }
    },
    //关闭认证弹窗
    close() {
      this.ren_flag = false;
    },
    //去认证
    gorealName() {
      this.$router.push({ path: "realName" });
    },
    //选择认领方式
    dian(i) {
      if (event.target.id == "websit") {
        this.websitFlag = true;
        this.photoFlag = false;
      }
      if (event.target.id == "photo") {
        this.photoFlag = true;
        this.websitFlag = false;
      }
    },

    // 触发上传事件
    uploadFile() {
      this.$refs.backFile.value = "";
      this.$refs.backFile.dispatchEvent(new MouseEvent("click"));
    },
    //认领上传文件
    async doRealUpload(e) {
      let file = e.target.files[0];
      console.log(file);
      this.flie = file ? file.name : "";
      let form = new FormData();
      form.append("file", file);
      const res = await upload(form);
      if (res.code === 200) {
        this.fileUrl = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    //纠错上传文件
    getup() {
      var that = this;
      document.getElementById("file").addEventListener("change", function () {
        that.file = document.getElementById("file").value;
      });
    },
    //关闭认领弹窗
    closecl() {
      this.claimn_flag = false;
      //清空相关信息
      this.website = "";
      this.r_remark = "";
      this.fileUrl = "";
      this.flie = "";
      this.photoFlag = false;
      this.websitFlag = false;
      document.getElementById("photos").value == "";
    },
    //提交认领
    async submit() {
      //是否选择认领方式
      if (!this.websitFlag && !this.photoFlag) {
        this.$message.error("请选择认领方式");
        return;
      }

      if (!this.r_remark) {
        this.$message("请输入备注");
        return;
      }
      //是否选择网址佐证
      if (this.websitFlag) {
        //是否输入网址
        if (this.website == "") {
          this.$message.error("请输入网址");
          return;
        } else {
          //网址正则验证
          var reg =
            /[http://|ftp://|https://|www]?[^\u4e00-\u9fa5\s]*?\.[com|net|cn|me|tw|fr][^\u4e00-\u9fa5\s]*/;
          if (reg.test(this.website)) {
            //参数
            let data = {
              md5: this.tableData[this.id].textMd5,
              applyType: 2,
              websiteUrl: this.website,
              remark: this.r_remark,
            };
            //发送请求
            let res = await apply(data);
            //判断响应是否成功
            if (res.code == 200) {
              //关闭弹窗
              this.claimn_flag = false;
              //清空相关信息
              this.website = "";
              this.r_remark = "";
              this.websitFlag = false;
              //显示成功弹窗
              this.success_flag = true;
              setTimeout(this.shut, 3000);
            }
          } else {
            this.$message.error("请输入正确的网址格式");
            return;
          }
        }
      }
      //是否选择资料佐证
      if (this.photoFlag) {
        //是否上传资料
        if (this.fileUrl == "") {
          this.$message.error("请上传照片或资料");
          return;
        }

        //参数
        let data = {
          md5: this.tableData[this.id].textMd5,
          applyType: 3,
          fileUrl: this.fileUrl,
          remark: this.r_remark,
        };
        //发送数据
        let res = await apply(data);
        if (res.code == 200) {
          this.claimn_flag = false;
          this.success_flag = true;
          this.r_remark = "";
          this.flie = "";
          this.fileUrl = "";
          this.photoFlag = false;
          document.getElementById("photos").value == "";
          setTimeout(this.shut, 3000);
        }
      }
    },
    //关闭成功提示
    shut() {
      this.success_flag = false;
    },
    //关闭纠错弹窗
    closeer() {
      this.error_flag = false;
      //清空相关信息
      this.owner = "";
      this.websiter = "";
      this.remark = "";
      this.flie = "";
      this.fileUrl = "";
      document.getElementById("file").value == "";
    },
    //纠错提交
    async submiter() {
      //是否输入著作权人
      if (this.owner == "") {
        this.$message.error("请输入准确著作权人");
        return;
      }
      //如果输入网址
      if (this.websiter != "") {
        //网址正则验证
        var reg =
          /[http://|ftp://|https://|www]?[^\u4e00-\u9fa5\s]*?\.[com|net|cn|me|tw|fr][^\u4e00-\u9fa5\s]*/;

        if (!reg.test(this.websiter)) {
          this.$message.error("请输入正确的网址格式");
          return;
        }
      }
      //参数
      let data = {
        md5: this.tableData[this.id].textMd5,
        currentUserId: this.tableData[this.id].originalId,
        currentAuthor: this.owner,
        websiteUrl: this.websiter,
        fileUrl: this.fileUrl,
        remark: this.remark,
      };
      //发送数据
      let res = await error(data);
      //是否响应成功
      if (res.code == 200) {
        this.error_flag = false;
        this.success_flag = true;
        this.owner = "";
        this.websiter = "";
        this.remark = "";
        this.flie = "";
        this.fileUrl = "";
        document.getElementById("file").value == "";
        setTimeout(this.shut, 3000);
      }
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss">
.el-tooltip__popper {
  max-width: 400px;
  margin-left: 80px;
}
.el-tooltip__popper.is-dark {
  background: #fff !important;
  color: #606266 !important;
  font-size: 14px;
  box-shadow: 0 3px 3px 3px rgba(151, 151, 158, 0.5);
}
</style>
<style lang="scss" scoped>
.included {
  height: 800px;
}
.nofile {
  text-align: center;
  margin-top: 150px;
  margin-bottom: 150px;
  div {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    margin-top: 20px;
  }
}
.display {
  display: none !important;
}
::v-deep .el-pagination {
  button {
    width: 80px;
  }
}
.nav {
  display: flex;
  margin-top: 22px;
  margin-left: 40px;

  .includednav_a {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    line-height: 21px;
    margin-right: 40px;
    cursor: pointer;
  }
}

.in_search {
  display: flex;
  justify-content: center;
  margin: 30px auto;

  input {
    width: 652px;
    height: 46px;
    background: #ffffff;
    border: 1px solid #ff6900;
    border-radius: 5px 0px 0px 5px;
    box-sizing: border-box;
    padding: 16px 21px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
  }

  .search {
    width: 62px;
    height: 47px;
    background: #ff6900;
    border-radius: 0px 5px 5px 0px;
    padding: 13px 21px;
    box-sizing: border-box;
    cursor: pointer;
  }
}

.tip {
  width: 334px;
  height: 12px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 21px;
  margin: 20px auto;
}

.state {
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
  span {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ff6900;
    cursor: pointer;
  }
}
.pagination {
  margin: 40px auto;
  text-align: center;
}
.claim {
  width: 340px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  position: fixed;
  top: 320px;
  left: 750px;
  .close {
    margin-top: 10px;
    margin-left: 317px;
    cursor: pointer;
  }
  .renzheng {
    display: flex;
    align-items: center;
    margin-left: 84px;
    margin-top: 41px;
    img {
      display: block;
      margin-right: 9px;
    }
    span {
      display: block;
      width: 160px;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .xuanze {
    display: flex;
    margin-top: 60px;
    .yes {
      width: 64px;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ff6900;
      margin-left: 54px;
      margin-right: 50px;
      cursor: pointer;
    }
    .no {
      margin-left: 65px;
      height: 15px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ff6900;
      cursor: pointer;
    }
    .xian {
      width: 1px;
      height: 20px;
      border-left: 1px solid #e8e8e8;
    }
  }
}
.claimn {
  z-index: 10;
  width: 428px;
  background: #ffffff;
  border: 0px solid #818790;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  position: fixed;
  top: 220px;
  left: 650px;
  .claimnNav {
    display: flex;
    width: 427px;
    height: 42px;
    background: #f4f6f7;
    .title {
      display: flex;
      align-items: center;
      margin-left: 10px;
      span {
        margin-left: 5px;
      }
    }
    .title_x {
      position: absolute;
      top: 14px;
      right: 10px;
      cursor: pointer;
      width: 14px;
      height: 14px;
    }
  }
  .wangzhi {
    margin-top: 21px;
    margin-left: 25px;
    cursor: pointer;
    label {
      width: 55px;
      height: 14px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .w-title {
    height: 14px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
    display: block;
    margin-top: 20px;
    margin-left: 30px;
  }
  .shuruw {
    width: 366px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #cecece;
    border-radius: 5px;
    margin-top: 20px;
    margin-left: 30px;
    box-sizing: border-box;
    padding: 18px 20px;
    display: block;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  textarea {
    width: 366px;
    height: 100px;
    background: #ffffff;
    border: 1px solid #cecece;
    border-radius: 5px;
    margin-top: 20px;
    margin-left: 30px;
    box-sizing: border-box;
    padding: 18px 20px;
    display: block;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    resize: none;
  }
  .file-tip {
    width: 366px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #cecece;
    border-radius: 5px;
    margin-top: -50px;
    margin-left: 30px;
    box-sizing: border-box;
    padding: 18px 20px;
    display: block;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ff6900;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .botton {
    display: flex;
    margin-left: 240px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.active {
  color: #ff6900 !important;
}
.success {
  width: 391px;
  background: #ffffff;
  border: 1px solid #ff6900;
  border-radius: 22px;
  position: absolute;
  top: 600px;
  left: 700px;
  box-sizing: border-box;
  padding: 16px 21px;
  span {
    width: 349px;
    height: 67px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 26px;
  }
  img {
    position: relative;
    top: 4px;
    margin-right: 10px;
  }
}
</style>
